import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  searchField: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    background: 'white',
    height: 'auto',
    padding: '10px 15px',
  },
}));
