import { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useStyles } from "@root/components/DragAndDrop/useStyles";

interface SortableItemProps {
  id: string;
  content: string;
}

export const SortableItem: FC<SortableItemProps> = ({ id, content }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const styles = useStyles();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} className={styles.item}>
      {content}
    </div>
  );
};
