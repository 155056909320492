import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { DragAndDrop } from '@root/components/DragAndDrop/dargAndDrop';
import { IModalProps } from '@root/components/DragAndDrop/types';
import { useStyles } from '@root/components/DragAndDrop/useStyles';
import { FC } from 'react';

export const DragAndDropModal: FC<IModalProps> = ({
  id,
  open,
  handleClose,
  selectedOptions,
  setSelectedOptions,
  onSave,
}) => {
  const styles = useStyles();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={styles.dialogTitle}>Edit Order</DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <DragAndDrop items={selectedOptions} setActiveItems={setSelectedOptions} />
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <Button onClick={handleClose} variant='contained' color='default'>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => (onSave(), handleClose())} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
