import { FormControl, Grid, IconButton, TableCell, TableRow, TextField } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { Autocomplete } from '@material-ui/lab';
import Can, { Permissions } from '@root/components/Can';
import DeleteModalButton from '@root/components/DeleteModalButton';
import InputSelect from '@root/components/InputSelect';
import { useStyles } from '@root/pages/BundleGroups/styles';
import { GroupPlanType, IBundleGroup } from '@root/services/api/requests/bundleGroups';
import {
  selectedProductsOptions,
  selectPlansByGroupId,
  selectTLevelGroupOptions,
} from '@root/store/entities/selectors';
import { IOption } from '@root/utils/commonTypes';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

interface IProps {
  item: IBundleGroup;
  onEditRow(p: IBundleGroup): Promise<boolean>;
  deleteNewItem(): void;
  deleteItem?(item: IBundleGroup): void;
}

const BundleGroupRow: React.FC<IProps> = ({ item, deleteNewItem, onEditRow, deleteItem }) => {
  const styles = useStyles();
  const [editable, setEditable] = useState(!item.id || false);
  const [itemForEdit, setItemForEdit] = useState<IBundleGroup>(item);
  const addonsOptions = useSelector(selectedProductsOptions);
  const bundlesOptions = useSelector(selectTLevelGroupOptions);
  const planOptions = [...bundlesOptions, ...addonsOptions];
  const [selectedOptionsValue, setSelectedOptionsValue] = useState<IOption[]>(() =>
    planOptions.filter((group) => item.planGroupIds.includes(group.value as number))
  );

  const { id, name, planGroupIds } = itemForEdit || {};

  const isValid = name && planGroupIds.length;

  const typeOptions = Object.values(GroupPlanType).map((item) => ({
    label: item,
    value: item,
  }));

  const setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    setItemForEdit((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearchPlan = (
    _: React.ChangeEvent<{}>,
    selectedOptions: IOption | IOption[] | null
  ) => {
    if (selectedOptions && Array.isArray(selectedOptions)) {
      setSelectedOptionsValue([...selectedOptions]);
      setItemForEdit((prev) => ({
        ...prev,
        planGroupIds: (selectedOptions as IOption[]).map((item) => item.value),
      }));
    }
  };

  const onSave = async () => {
    if (!isValid) {
      return;
    }

    setItemForEdit((prev) => ({
      ...prev,
      name: prev.name.trim(),
    }));

    const isSuccess = await onEditRow(itemForEdit);
    if (isSuccess) {
      setEditable(false);
    }
  };

  return (
    <TableRow key={id}>
      <TableCell align="left">{id}</TableCell>
      <TableCell align="left">
        {editable ? (
          <TextField
            required
            value={name}
            name="name"
            onChange={setValue}
            error={!name}
            helperText={!name && 'Required'}
          />
        ) : (
          name
        )}
      </TableCell>

      <TableCell align="left">
        {editable ? (
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                id="planGroupIds"
                options={planOptions}
                value={selectedOptionsValue}
                onChange={handleSearchPlan}
                getOptionLabel={(option) => option.label}
                renderOption={(option) => <span>{option.label}</span>}
                multiple
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    className={styles.searchField}
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
              {/* <Select
                id="planGroupIds"
                name="planGroupIds"
                label="Plans"
                variant="outlined"
                multiple
                value={planGroupIds}
                onChange={(e) => setValue(e as React.ChangeEvent<HTMLInputElement>)}
                input={<Input />}
                renderValue={(selected) => (selected as string[]).join(', ')}
                MenuProps={MenuProps}
              >
                <Input placeholder="Search a plan" value={searchPlans} onChange={(e) => setSearchPlans(e.target.value)} className={styles.searchField} fullWidth />
                  {planOptions.map(({ value, label }) => (
                    <MenuItem key={label} value={value}>
                      <Checkbox
                        checked={planGroupIds.some(
                          (item) => item?.toString() === value?.toString()
                        )}
                      />
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
              </Select> */}
            </FormControl>
          </Grid>
        ) : (
          planGroupIds
            .map((item) => planOptions.find((option) => option.value === item)?.label)
            .sort()
            .join(', ')
        )}
      </TableCell>
      <TableCell align="left">
        <Grid item xs={12}>
          <InputSelect
            id="type"
            placeholder="Type"
            name="type"
            variant="standard"
            fullWidth
            options={typeOptions}
            value={itemForEdit.type}
            onChange={(e) => setValue(e as React.ChangeEvent<HTMLInputElement>)}
            disabled={!editable}
          />
        </Grid>
      </TableCell>

      <TableCell align="left">
        {editable ? (
          <>
            <IconButton
              aria-label="edit"
              title={'Cancel'}
              size={'small'}
              onClick={() => {
                setEditable(false);
                setItemForEdit(item);
                !item.id && deleteNewItem();
              }}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              type="button"
              aria-label="edit"
              disabled={!isValid}
              title={'Save'}
              size={'small'}
              onClick={onSave}
            >
              <CheckIcon />
            </IconButton>
          </>
        ) : (
          <>
            <Can perform={Permissions.updateBundleGroup}>
              <IconButton
                aria-label="edit"
                title={'Edit'}
                size={'small'}
                onClick={() => setEditable(true)}
              >
                <EditIcon />
              </IconButton>
            </Can>
            <Can perform={Permissions.deleteBundleGroup}>
              <DeleteModalButton
                name={name}
                entity={'Bundle Group'}
                onDelete={() => deleteItem?.(item)}
              />
            </Can>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default BundleGroupRow;
