import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import BundleGroupRow from '@root/pages/BundleGroups/editable-row';
import {
  addBundleGroup,
  deleteBundleGroup,
  editBundleGroup,
  getBundleGroups,
  IBundleGroup,
} from '@root/services/api/requests/bundleGroups';
import { IMeta } from '@root/services/api/types';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import { selectUserPermissions } from '../../store/system/selectors';
import setNotification from '../../utils/notifications';
import { emptyItem, headCells } from './static-data';

const BundleGroups: FC = () => {
  const classes = useStandardTableStyles();
  const permissions = useSelector(selectUserPermissions);
  const [list, setList] = useState<IBundleGroup[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [newItem, setNewItem] = React.useState<IBundleGroup | null>(null);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [meta, setMeta] = useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const fetchList = async () => {
    try {
      const response = await getBundleGroups({ page, limit: rowsPerPage });
      setList(response.data.items);
      setMeta(response.data.meta);
    } catch (e) {
      console.error('fetch KYC List error', e);
    } finally {
      setLoading(false);
    }
  };

  const addUpdateRow = async (data: IBundleGroup) => {
    try {
      const params = {
        ...data,
        name: data.name.trim()
      };
      if (data.id) {
        await editBundleGroup(params);
      } else {
        await addBundleGroup(params);
      }
      setNotification('success', {
        message: 'Success',
      });
      setNewItem(null);
      fetchList();
      return true;
    } catch (e) {
      console.error('UPDATE BUNDLE GROUP ITEM ERROR', e);
      return false;
    }
  };

  const addNewItem = () => {
    setNewItem(emptyItem);
  };

  const deleteNewItem = () => {
    setNewItem(null);
  };

  const deleteItem = async (item: IBundleGroup) => {
    try {
      await deleteBundleGroup(item.id as number);
      setNotification('success', {
        message: 'Success',
      });
      fetchList();
    } catch (e) {
      console.error('DELETE BUNDLE GROUP ERROR', e);
    }
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    if (permissions?.includes(Permissions.readKycVerificationListLevel1)) {
      fetchList();
    }
  }, [page, rowsPerPage]);

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Plan Grouping</Title>
            <Can perform={Permissions.createRecurringType}>
              <Box display={'flex'}>
                <Button
                  onClick={addNewItem}
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<AddCircleIcon />}
                >
                  Add Plan Grouping
                </Button>
              </Box>
            </Can>
          </div>
        </Paper>
      </Grid>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <CustomTableHead headCells={headCells} order={'asc'} orderBy={'id'} classes={classes} />
            <TableBody>
              {loading && <TableSkeleton />}
              {!loading && newItem && (
                <BundleGroupRow
                  key="new-item"
                  onEditRow={addUpdateRow}
                  deleteNewItem={deleteNewItem}
                  item={newItem}
                />
              )}
              {!loading &&
                list?.map((item) => (
                  <BundleGroupRow
                    key={item.id}
                    onEditRow={addUpdateRow}
                    deleteNewItem={deleteNewItem}
                    deleteItem={deleteItem}
                    item={item}
                  />
                ))}
              {!loading && !list?.length && (
                <TableRow>
                  <TableCell>There are no Recurring Types</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onPageChange={(e: unknown, newPage: number) => handleChangePage(e, newPage)}
          onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeRowsPerPage(event)
          }
        />
      </Paper>
    </div>
  );
};

export default BundleGroups;
