import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams } from '../types';

export enum SETTING_TYPES {
  PRIORITY = 'priority',
  SELECT = 'select',
  MULTISELECT = 'multiselect',
}

export interface IMethod {
  id: number;
  name: string;
  defaultProcessingFee: number;
  priority: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface ISetting {
  id: number;
  code: string;
  name: string;
  options: string[];
  value: string | boolean | string[];
  createdAt: Date;
  updatedAt: Date;
  type: SETTING_TYPES;
}

export interface ISettingResponse {
  data: {
    items: ISetting[];
    meta: IMeta;
  };
}

const request = ({ page, limit }: IPaginationParams) => {
  const query = `?page=${page}&limit=${limit}`;
  return apiClient.get<ISettingResponse>(ApiUrls.settingsList + query).then((response) => response);
};

export default request;
