import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontWeight: 700,
  },

  dialogContent: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    flexWrap: 'wrap',
    overflow: 'hidden',
    alignItems: 'center',
  },

  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  item: {
    width: 250,
    padding: '10px 15px',
    borderRadius: 5,
    textAlign: 'center',
    background: theme.palette.grey[400],
    cursor: 'pointer',
  },
}));
