import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { SortableItem } from "@root/components/DragAndDrop/sortableItem";
import { Dispatch, FC, SetStateAction } from "react";

interface DragAndDropProps {
  items: string[];
  setActiveItems: Dispatch<SetStateAction<string[]>>;
}

export const DragAndDrop: FC<DragAndDropProps> = ({ items, setActiveItems }) => {
  
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setActiveItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items}>
        {items.map((id) => (
          <SortableItem key={id} id={id} content={id} />
        ))}
      </SortableContext>
    </DndContext>
  );
};
