import { IMeta } from '@root/services/api/types';
import qs from 'qs';
import ApiUrls from '../apiUrls';
import apiClient from '../client';

export enum GroupPlanType {
  BUNDLE = 'bundle',
  ADDON = 'addon'
}

export interface IBundleGroup {
  id?: number;
  planGroupIds: number[];
  name: string;
  type: GroupPlanType;
}

interface IParams {
  limit: number;
  page: number;
}

export const addBundleGroup = (data: IBundleGroup) =>
  apiClient.post(ApiUrls.bundleGroup, data).then((response) => response);

export const getBundleGroups = (params: IParams) =>
  apiClient
    .get<{
      data: {
        items: IBundleGroup[];
        meta: IMeta;
      };
    }>(ApiUrls.bundleGroup + '/list?' + qs.stringify(params))
    .then((response) => response.data);

export const editBundleGroup = (data: IBundleGroup) => {
  const url = `${ApiUrls.bundleGroup}/${data.id}`;
  return apiClient.put(url, data).then((response) => response);
};

export const deleteBundleGroup = (id: number) => {
  const url = `${ApiUrls.bundleGroup}/${id}`;
  return apiClient.delete(url).then((response) => response);
};
